import Vue from "vue";
import VueRouter from "vue-router";

// const Navbar = () => import("@/views/nav/Index.vue");
// const Layout = () => import("@/views/layout/Index.vue");
const Home = () => import("@/views/home/Home.vue");
const Ranking = () => import("@/views/home/Ranking.vue");
const News = () => import("@/views/home/News.vue");
const Market = () => import("@/views/home/Market.vue");
const Map = () => import("@/views/home/Map.vue");
const Login = () => import("@/views/login/Index.vue");
const Signup = () => import("@/views/signup/Index.vue");
const Items = () => import("@/views/items/Items.vue");
const ListItems = () => import("@/views/items/ListItems.vue");
const MyBuy = () => import("@/views/items/MyBuy.vue");
const MySell = () => import("@/views/items/MySell.vue");
const MyItems = () => import("@/views/items/MyItems.vue");
const Account = () => import("@/views/personal/Account.vue");
const AccountEdit = () => import("@/views/personal/AccountEdit.vue");
const Creator = () => import("@/views/personal/Creator.vue");
const Submit = () => import("@/views/personal/Submit.vue");
const OriginItems = () => import("@/views/items/origin-items/item.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Home,
    name: "Home",
  },
  {
    path: "/ranking",
    component: Ranking,
    name: "Ranking",
  },
  {
    path: "/news",
    component: News,
    name: "News",
  },
  {
    path: "/market",
    component: Market,
    name: "Market",
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/signup",
    component: Signup,
    name: "Signup",
  },
  {
    path: "/list-items",
    component: ListItems,
    name: "ListItems",
  },
  {
    path: "/my-buy",
    component: MyBuy,
    name: "MyBuy",
    children: [
      {
        path: "/my-buy/:id",
        component: OriginItems,
        name: "OriginItems",
      },
    ],
  },
  {
    path: "/my-sell",
    component: MySell,
    name: "MySell",
    children: [
      {
        path: "/my-sell/:id",
        component: OriginItems,
        name: "OriginItems",
      },
    ],
  },
  {
    path: "/my-items",
    component: MyItems,
    name: "MyItems",
    children: [
      {
        path: "/my-items/:id",
        component: OriginItems,
        name: "OriginItems",
      },
    ],
  },

  {
    path: "/items/:id",
    component: Items,
    name: "Items",
  },
  {
    path: "/account",
    component: Account,
    name: "Account",
  },
  {
    path: "/account/edit",
    component: AccountEdit,
    name: "AccountEdit",
  },
  {
    path: "/creator",
    component: Creator,
    name: "Creator",
  },
  {
    path: "/submit",
    component: Submit,
    name: "Submit",
  },
  {
    path: "/map",
    component: Map,
    name: "Map",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
