import Vue from 'vue'
import {Message} from 'element-ui'
import App from './App.vue'
import './plugins/element.js'
import '@/styles/global.css'
import router from './router'
import 'normalize.css'
import VueAMap from 'vue-amap';
import axios from 'axios'
import infiniteScroll from "vue-infinite-scroll";
import { queryAuth } from './api/api'
import ElementUI from 'element-ui'; 
 


Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueAMap);

VueAMap.initAMapApiLoader({
  key: 'a26a49235d22aa7028d57c0aa13fe157',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  // v: '1.4.4',
  // 引用高德ui组件
  // uiVersion: '1.0.11',
});

Vue.use(infiniteScroll);

new Vue({
  el: "#app",
  router,
  render: h => h(App),
}).$mount('#app');

// 设置 Axios 拦截器以在每个请求中携带 token  
axios.interceptors.request.use(config => {  
  const token = localStorage.getItem('token');  
  if (token) {  
      config.headers['Authorization'] = `Bearer ${token}`;  
  }  
  return config;  
}, error => {  
  return Promise.reject(error);  
});


queryAuth().then(res => {
  if (!res.status === 200 || !res.data.data){
    Message({
      message: '登录失效，请重新登录',
      type: 'warning'
  });
    router.replace('/login')
  }
})




router.beforeEach((to, from, next) => {  
  const token = localStorage.getItem('token');  
  const allowedRoutes = ['/login', '/signup', '/'];  
  if (!token && !allowedRoutes.includes(to.path)) {  
    // 直接使用 Element UI 的 Message 组件  
    Message({  
      message: '请先登录！',  
      type: 'warning' // 通常警告更适合这种情况，而不是 'success'  
    });  
    next('/login');  
  } 
  else {  
    next();
  }  
});