
import request from './http'

export function queryLogin(data){
    return request ({
        url: "/apis/login",
        method: "post",
        data
    })
}

export function queryAuth(){
    return request ({
        url: "/apis/auth",
        method: "get",
    })
}

export function querySignup(data){
    return request ({
        url: "/apis/signup",
        method: "post",
        data
    })
}

export function queryMyAccount() {  
    return request({  
        url: "/apis/account",  
        method: "get",
    });  
}

export function queryItems(id){
    return request ({
        url: `/apis/items/${id}`,
        method: "get"
    })
}

export function queryItemsTrade(id){
    return request ({
        url: `/apis/items_trade/${id}`,
        method: "get"
    })
}

export function queryMyItems(){
    return request ({
        url: `/apis/my-items/`,
        method: "get"
    })
}

export function queryMyItemsOne(limit,offset){ // 单个单个取得自己的物品
    return request ({
        url: `/apis/my-items/${limit}/${offset}`,
        method: "post",
        data: {
            limit,
            offset
        }
    })
}

export function queryOthersItems(id){
    return request ({
        url: `/apis/my-items/${id}`,
        method: "get"
    })
}

export function updateMyAccount(data){
    return request ({
        url: "/apis/account/edit",
        method: "post",
        data
    })
}

